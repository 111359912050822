import React from "react";
import PropTypes from "prop-types";

import Box from "@pagerland/common/src/components/Box";
import Fade from "react-reveal/Fade";
import Typography from "@pagerland/common/src/components/Typography";
import Container from "@pagerland/common/src/components/Container";
import Grid from "@pagerland/common/src/components/Grid";

import Button from "@pagerland/common/src/components/Button";
import Article from "../../components/Article";

import data from "../../data";

const Legal = ({
  name,
  title,
  text,
  WrapperProps,
  ContainerProps,
  CaptionProps,
  TitleProps,
  TextProps,
  CtaProps,
  cta,
}) => (
  <Box name={name} {...WrapperProps}>
    <Container {...ContainerProps}>
      <Button {...CtaProps} {...cta}>
        {cta.label}
      </Button>
      <Box>
        <Typography>
          <p>
            <h1>Legal Notice</h1>
            Information in accordance with §5 of the E-Commerce Act, §14 of the
            Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure
            requirements under §25 of the Media Act.<br></br>
            <p>
              MAAI digital GmbH<br></br>
              Gesellschaft mit beschränkter Haftung (GmbH)<br></br>
            </p>
            <strong>Business purpose:</strong> Hardware and software
            development; Service and consulting
            <br></br>
            <strong>UID:</strong> ATU77074817<br></br>
            <strong>Corporate register number:</strong> 560183z<br></br>
            <strong>Commercial register court:</strong> Salzburg District Court
            <br></br>
            <strong>Company location:</strong> Salzburger Str. 10, 5301
            Eugendorf, Austria<br></br>
            <strong>Email:</strong> info@aichriedler.de<br></br>
            <strong>Web:</strong> www.maai.digital<br></br>
            <strong>Chief executive:</strong> Martin Aichriedler<br></br>
            <h1>Privacy policy</h1>
            <h2>Privacy Policy</h2>
            We have written this privacy policy (version 17.07.2021-121783550)
            in order to explain to you, in accordance with the provisions of the
            General Data Protection Regulation (EU) 2016/679 and applicable
            national laws, which personal data (data for short) we as the
            controller – and the processors commissioned by us (e.g. providers)
            – process, will process in the future and what legal options you
            have. The terms used are to be considered as gender-neutral.
            <p>
              In short: We provide you with comprehensive information about any
              personal data we process about you.
            </p>
            <p>
              Privacy policies usually sound very technical and use legal
              terminology. However, this privacy policy is intended to describe
              the most important things to you as simply and transparently as
              possible. So long as it aids transparency, technical terms are
              explained in a reader-friendly manner, links to further
              information are provided and graphics are used. We are thus
              informing in clear and simple language that we only process
              personal data in the context of our business activities if there
              is a legal basis for it. This is certainly not possible with
              brief, unclear and legal-technical statements, as is often
              standard on the Internet when it comes to data protection. I hope
              you find the following explanations interesting and informative.
              Maybe you will also find some information that you have not been
              familiar with.
            </p>
            <p>
              If you still have questions, we would like to ask you to contact
              the responsible body named below or in the imprint, to follow the
              existing links and to look at further information on third-party
              sites. You can of course also find our contact details in the
              imprint.
            </p>
            <h2>Scope</h2>
            This privacy policy applies to all personal data processed by our
            company and to all personal data processed by companies commissioned
            by us (processors). With the term personal data, we refer to
            information within the meaning of Article 4 No. 1 GDPR, such as the
            name, email address and postal address of a person. The processing
            of personal data ensures that we can offer and invoice our services
            and products, be it online or offline. The scope of this privacy
            policy includes:
            <ul>
              <li>
                all online presences (websites, online shops) that we operate
              </li>
              <li>Social media presences and email communication</li>
              <li>mobile apps for smartphones and other devices</li>
            </ul>
            In short: This privacy policy applies to all areas in which personal
            data is processed in a structured manner by the company via the
            channels mentioned. Should we enter into legal relations with you
            outside of these channels, we will inform you separately if
            necessary.
            <h2>Legal bases</h2>
            <p>
              In the following privacy policy, we provide you with transparent
              information on the legal principles and regulations, i.e. the
              legal bases of the General Data Protection Regulation, which
              enable us to process personal data. Whenever EU law is concerned,
              we refer to REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT
              AND OF THE COUNCIL of April 27, 2016. You can of course access the
              General Data Protection Regulation of the EU online at EUR-Lex,
              the gateway to EU law, at
              https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679.
            </p>
            <p>
              We only process your data if at least one of the following
              conditions applies:
              <ol>
                <li>
                  Consent (Article 6 Paragraph 1 lit. a GDPR): You have given us
                  your consent to process data for a specific purpose. An
                  example would be the storage of data you entered into a
                  contact form.
                </li>
                <li>
                  Contract (Article 6 Paragraph 1 lit. b GDPR): We process your
                  data in order to fulfill a contract or pre-contractual
                  obligations with you. For example, if we conclude a sales
                  contract with you, we need personal information in advance.
                </li>
                <li>
                  Legal obligation (Article 6 Paragraph 1 lit. c GDPR): If we
                  are subject to a legal obligation, we will process your data.
                  For example, we are legally required to keep invoices for our
                  bookkeeping. These usually contain personal data.
                </li>
                <li>
                  Legitimate interests (Article 6 Paragraph 1 lit. f GDPR): In
                  the case of legitimate interests that do not restrict your
                  basic rights, we reserve the right to process personal data.
                  For example, we have to process certain data in order to be
                  able to operate our website securely and economically.
                  Therefore, the processing is a legitimate interest.
                </li>
              </ol>
            </p>
            Other conditions such as making recordings in the interest of the
            public, the exercise of official authority as well as the protection
            of vital interests do not usually occur with us. Should such a legal
            basis be relevant, it will be disclosed in the appropriate place.
            <p>
              In addition to the EU regulation, national laws also apply:
              <ul>
                <li>
                  In Austria this is the Austrian Data Protection Act
                  (Datenschutzgesetz), in short DSG.
                </li>
                <li>
                  In Germany this is the Federal Data Protection Act
                  (Bundesdatenschutzgesetz), in short BDSG.
                </li>
              </ul>
              Should other regional or national laws apply, we will inform you
              about them in the following sections.
            </p>
            <h2>Storage Period</h2>
            <p>
              It is a general criterion for us to store personal data only for
              as long as is absolutely necessary for the provision of our
              services and products. This means that we delete personal data as
              soon as any reason for the data processing no longer exists. In
              some cases, we are legally obliged to keep certain data stored
              even after the original purpose no longer exists, such as for
              accounting purposes.
            </p>
            <p>
              If you want your data to be deleted or if you want to revoke your
              consent to data processing, the data will be deleted as soon as
              possible, provided there is no obligation to continue its storage.
            </p>
            <p>
              We will inform you below about the specific duration of the
              respective data processing, provided we have further information.
            </p>
            <h2>
              Rights in accordance with the General Data Protection Regulation
            </h2>
            According to Article 13 of the GDPR, you have the following rights
            to ensure your data is processed fairly and transparently:
            <ul>
              <li>
                According to Article 15 of the GDPR, you have a right to
                information as to whether we are processing your data. If this
                is the case, you have the right to receive a copy of the data in
                question, as well as the following information:
                <ul>
                  <li>the purpose of the processing;</li>
                  <li>the categories of personal data concerned; </li>
                  <li>
                    the recipients to whom the personal data is disclosed, and
                    in particular how security can be guaranteed if the data is
                    transferred to third countries;
                  </li>
                  <li>how long the data will be stored;</li>
                  <li>
                    the existence of the right to request rectification, erasure
                    or restriction of processing of personal data and the right
                    to object to such processing;
                  </li>
                  <li>
                    the right to lodge a complaint with a supervisory authority
                    (you can find links to these authorities below);
                  </li>
                  <li>
                    the source of the data, if we have not collected it from you
                    directly;
                  </li>
                  <li>
                    whether profiling is carried out, i.e. whether data is
                    automatically evaluated in order to be allocated to a
                    personal profile on you.
                  </li>
                </ul>
              </li>
              <li>
                According to Article 16 of the GDPR, you have the right to
                request rectification of the data, which means that we have to
                correct your data should you find any errors.
              </li>
              <li>
                According to Article 17 of the GDPR, you have the right to have
                your personal data erased (the “right to be forgotten”), which
                specifically means that you can request to have your data
                deleted.
              </li>
              <li>
                According to Article 18 of the GDPR, you have the right to
                obtain restriction of processing, which means that we are only
                allowed to store the data but no longer use or process it.
              </li>
              <li>
                According to Article 20 of the GDPR, you have the right to
                request data portability, which means that on request, we will
                provide you with your data in a common format.
              </li>
              <li>
                According to Article 21 of the GDPR, you have a right to object,
                which will result in a change in processing after
                implementation.
                <ul>
                  <li>
                    If the processing of your data is based on Article 6 (1) (e)
                    (public interest, exercise of official authority) or Article
                    6 (1) (f) (legitimate interest), you can object to the
                    processing. We will then check as soon as possible, whether
                    we can legally comply with this objection.
                  </li>
                  <li>
                    If data is used to operate direct mail, you can object to
                    this type of data processing at any time. We will then no
                    longer be allowed to use your data for direct marketing
                    purposes.
                  </li>
                  <li>
                    If your data is used for profiling, you can object to this
                    type of data processing at any time. We will then no longer
                    be allowed to use your data for profiling purposes.
                  </li>
                </ul>
              </li>
              <li>
                According to Article 22 of the GDPR, you may have the right not
                to be subject to a decision based solely on automated processing
                (including profiling).
              </li>
            </ul>
            <p>
              If you believe that the processing of your data violates the data
              protection law, or your data protection claims have been violated
              in any other way, you can complain to the supervisory authority.
              For Austria this is the Data Protection Authority, whose website
              you can find at https://www.data-protection-authority.gv.at/ and
              for Germany you can contact the Federal Commissioner for Data
              Protection and Freedom of Information (BfDI).
            </p>
            <p>
              In short: You have rights – do not hesitate to contact our
              responsible person or authority listed above!
            </p>
            <p>All texts are copyrighted.</p>
            <p>Source: Created with the Datenschutz Generator by AdSimple</p>
          </p>
        </Typography>
      </Box>
    </Container>
  </Box>
);

Legal.propTypes = {
  /**
   * Name of container, can be used for anchors
   */
  name: PropTypes.string.isRequired,
  /**
   * Wrapper props
   * @See @pagerland/common/src/components/Box
   */
  WrapperProps: PropTypes.object,
  /**
   * Component container props
   * @See @pagerland/common/src/components/Container
   */
  ContainerProps: PropTypes.object,
  /**
   * Caption props
   * @See @pagerland/common/src/components/Box
   */
  CaptionProps: PropTypes.object,
  /**
   * Title text props
   * @See @pagerland/common/src/components/Typography
   */
  TitleProps: PropTypes.object,
  /**
   * Main text props
   * @See @pagerland/common/src/components/Typography
   */
  TextProps: PropTypes.object,
  /**
   * Props of services grid wrapper
   * @See @pagerland/common/src/components/Grid
   */
  GridProps: PropTypes.object,
  /**
   * Title node of component
   */
  title: PropTypes.node,
  /**
   * Main description
   */
  text: PropTypes.node,
  /**
   * List of articles
   */
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      date: PropTypes.node,
      title: PropTypes.node,
      text: PropTypes.node,
    })
  ),
  /**
   * Props of button below grid
   * @See @pagerland/common/src/components/Button
   */
  CtaProps: PropTypes.object,
  /**
   * Cta button details
   */
  cta: PropTypes.object,
};

Legal.defaultProps = {
  WrapperProps: {
    py: {
      _: 56,
      md: 64,
      lg: 96,
    },
  },
  CaptionProps: {
    mb: {
      _: 4,
      lg: 5,
    },
  },
  TitleProps: {
    as: "h2",
    variant: "h2",
    color: "black",
    mb: 3,
    textAlign: "center",
  },
  TextProps: {
    color: "gray.1",
    mb: 4,
  },
  GridProps: {
    gridColumnGap: {
      _: "16px",
      md: "32px",
    },
    gridRowGap: {
      _: "24px",
      md: "48px",
    },
    gridTemplateColumns: {
      _: "1fr 1fr",
      md: "2fr 1fr 1fr",
    },
    gridTemplateAreas: {
      _: '"article1 article1" "article2 article3" "article4 article5"',
      md: '"article1 article2 article3" "article1 article4 article5"',
    },
    mb: {
      _: 4,
      lg: 5,
    },
  },
  CtaWrapperProps: {
    textAlign: "center",
  },
  CtaProps: {
    textAlign: "center",
    mx: "auto",
    variant: "accent",
    as: "a",
  },
  ...data.legal,
  cta: {
    label: "Go back",
    href: "https://maai.digital",
  },
};

export default Legal;
