import { smoothLinkProps } from "@pagerland/common/src/utils";
import { Link } from "react-scroll";

import textToMultiline from "@pagerland/common/src/utils/textToMultiline";

import MapMarker from "@pagerland/icons/src/monochrome/MapMarker";
import MobilePhone from "@pagerland/icons/src/monochrome/MobilePhone";
import PaperAirplane from "@pagerland/icons/src/monochrome/PaperAirplane";

import FacebookF from "@pagerland/icons/src/monochrome/FacebookF";
import Instagram from "@pagerland/icons/src/monochrome/Instagram";
import Twitter from "@pagerland/icons/src/monochrome/Twitter";
import Linkedin from "@pagerland/icons/src/monochrome/Linkedin";

import * as Yup from "yup";
// import Welcome from './assets/welcome.jpg';
// import Welcome2x from './assets/welcome@2x.jpg';
import Welcome from "./assets/5_1.png";
import Welcome2x from "./assets/5_1@2x.png";
import AboutUs from "./assets/about-us.jpg";
import AboutUs2x from "./assets/about-us@2x.jpg";
import AboutApp from "./assets/1_1.png";
import AboutApp2x from "./assets/1_1@2x.png";
import AboutWeb from "./assets/30_1.png";
import AboutWeb2x from "./assets/30_1@2x.png";
import AboutIot from "./assets/20_1.png";
import AboutIot2x from "./assets/20_1@2x.png";

import Avatar1 from "./assets/avatars/avatar-1.jpg";
import Avatar12x from "./assets/avatars/avatar-1@2x.jpg";
import Avatar2 from "./assets/avatars/avatar-2.jpg";
import Avatar22x from "./assets/avatars/avatar-2@2x.jpg";
import Avatar3 from "./assets/avatars/avatar-3.jpg";
import Avatar32x from "./assets/avatars/avatar-3@2x.jpg";
import Avatar4 from "./assets/avatars/avatar-4.jpg";
import Avatar42x from "./assets/avatars/avatar-4@2x.jpg";
import Avatar5 from "./assets/avatars/avatar-5.jpg";
import Avatar52x from "./assets/avatars/avatar-5@2x.jpg";
import Avatar6 from "./assets/avatars/avatar-6.jpg";
import Avatar62x from "./assets/avatars/avatar-6@2x.jpg";
import Avatar7 from "./assets/avatars/avatar-7.jpg";
import Avatar72x from "./assets/avatars/avatar-7@2x.jpg";
import Avatar8 from "./assets/avatars/avatar-8.jpg";
import Avatar82x from "./assets/avatars/avatar-8@2x.jpg";

import Estimations from "./assets/estimation.svg";
import Ecommerce from "./assets/ecommerce.svg";
import Support from "./assets/support.svg";
import Build from "./assets/build.svg";
import Design from "./assets/design.svg";
import Research from "./assets/research.svg";
import Verified from "./assets/verified.svg";

import Android from "./assets/android.svg";
import Angular from "./assets/angular.svg";
import Aws from "./assets/aws.svg";
import Docker from "./assets/docker.svg";
import Firebase from "./assets/firebase.svg";
import Flutter from "./assets/flutter.svg";
import Graphql from "./assets/graphql.svg";
import Ionic from "./assets/ionic.svg";
import Ios from "./assets/ios.svg";
import Java from "./assets/java.svg";
import Nodejs from "./assets/nodejs.svg";
import React from "./assets/react.svg";
import Sketch from "./assets/sketch.svg";

import PricingBasic from "./assets/pricing/basic.svg";
import PricingAdvanced from "./assets/pricing/advanced.svg";
import PricingExpert from "./assets/pricing/expert.svg";

import Article1 from "./assets/articles/article-1.jpg";
import Article12x from "./assets/articles/article-1@2x.jpg";
import Article2 from "./assets/articles/article-2.jpg";
import Article22x from "./assets/articles/article-2@2x.jpg";
import Article3 from "./assets/articles/article-3.jpg";
import Article32x from "./assets/articles/article-3@2x.jpg";
import Article4 from "./assets/articles/article-4.jpg";
import Article42x from "./assets/articles/article-4@2x.jpg";
import Article5 from "./assets/articles/article-5.jpg";
import Article52x from "./assets/articles/article-5@2x.jpg";

const thisYear = new Date();

export default {
  title: "MAAI digital",
  navbar: {
    links: [
      {
        to: "",
        label: "Home",
      },
      {
        to: "services",
        label: "Services",
      },
      {
        to: "projects",
        label: "Projects",
      },
      {
        to: "technology",
        label: "Technologies",
      },
      {
        to: "blog",
        label: "Blog",
      },
      {
        to: "contact",
        label: "Contact",
      },
    ],
    actions: [
      {
        to: "contact",
        label: "Contact us",
      },
    ],
  },
  welcome: {
    title: "Let's build your digital products together",
    text:
      "We passionately build digital products that users love. Thereby, we support you in every phase of the journey from ideation to market release.",
    actions: [
      {
        label: "Check our services",
        to: "services",
        as: Link,
        ...smoothLinkProps,
        variant: "secondary",
      },
    ],
    img: {
      src: Welcome,
      srcSet: `${Welcome} 1x, ${Welcome2x} 2x`,
    },
    avatars: [
      {
        src: Avatar1,
        srcSet: `${Avatar1} 1x, ${Avatar12x} 2x`,
      },
      {
        src: Avatar2,
        srcSet: `${Avatar2} 1x, ${Avatar22x} 2x`,
      },
      {
        src: Avatar3,
        srcSet: `${Avatar3} 1x, ${Avatar32x} 2x`,
      },
      {
        src: Avatar4,
        srcSet: `${Avatar4} 1x, ${Avatar42x} 2x`,
      },
      {
        src: Avatar5,
        srcSet: `${Avatar5} 1x, ${Avatar52x} 2x`,
      },
    ],
  },
  services: {
    title: "From lean design sprints to agile development",
    text:
      "We support you along the whole journey from ideation to growing successful products. Thereby, we work closely with you to understand your users' challenges and needs.",
    services: [
      {
        icon: Research,
        title: "1. Research",
        text:
          "We work with you to understand the user needs and validate your idea with real users using lean design sprints.",
      },
      {
        icon: Design,
        title: "2. Design",
        text:
          "Expanding on the insights gained, you’ll work closely with us to create an elegant design",
      },
      {
        icon: Verified,
        title: "3. Build",
        text:
          "With our scrum-based agile methodology, we continually provide iterative updates and visible progress.",
      },
    ],
    cta: {
      label: "Get in contact",
      to: "contact",
    },
  },
  aboutApp: {
    img: {
      src: AboutApp,
      srcSet: `${AboutApp} 1x, ${AboutApp2x} 2x`,
    },
    title: "Mobile development",
    alt: "mobile development",
    text:
      "We develop mobile apps in a fast and efficient way by using hybrid mobile frameworks like Flutter, React Native, or Ionic. The outcome is great apps that users love.",
    // cta: {
    //   label: "Learn more bout us",
    //   href:
    //     "https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567",
    // },
  },
  aboutWeb: {
    img: {
      src: AboutWeb,
      srcSet: `${AboutWeb} 1x, ${AboutWeb2x} 2x`,
    },
    title: "Web development",
    alt: "web development",
    text:
      "Every use case is different. We work with a broad technology stack to build modern web applications and intelligent artificial intelligence solutions that aim to exceed your expectations.",
  },
  aboutIot: {
    img: {
      src: AboutIot,
      srcSet: `${AboutIot} 1x, ${AboutIot2x} 2x`,
    },
    title: "IoT and hardware solutions",
    alt: "iot and hardware development",
    text:
      "We build new hardware products with rapid prototyping approaches. Furthermore, we support you in both hardware design and software engineering.",
  },
  team: {
    title: "We are a young business but we work with most modern technologies",
    text:
      "Human collaboration is at the center of our work. Around that, we work with modern technologies to build great digital products.",
    cta: {
      label: "Contact us",
      to: "contact",
    },
    people: [
      {
        avatar: {
          src: Android,
        },
        name: "Android",
      },
      {
        avatar: {
          src: Angular,
        },
        name: "Angular",
      },
      {
        avatar: {
          src: Aws,
        },
        name: "AWS",
      },
      {
        avatar: {
          src: Docker,
        },
        name: "Docker",
      },
      {
        avatar: {
          src: Firebase,
        },
        name: "Firebase",
      },
      {
        avatar: {
          src: Flutter,
        },
        name: "Flutter",
      },
      {
        avatar: {
          src: Graphql,
        },
        name: "GraphQL",
      },
      {
        avatar: {
          src: Ionic,
        },
        name: "Ionic",
      },
      {
        avatar: {
          src: Ios,
        },
        name: "iOS",
      },
      {
        avatar: {
          src: Java,
        },
        name: "Java",
      },
      {
        avatar: {
          src: Nodejs,
        },
        name: "NodeJs",
      },
      {
        avatar: {
          src: React,
        },
        name: "React",
      },
    ],
  },
  blog: {
    title: "Latest articles",
    articles: [
      {
        url: "#",
        src: Article1,
        srcSet: `${Article1} 1x, ${Article12x} 2x`,
        date: "Today",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        text:
          "Nullam sed tellus nec arcu mollis iaculis. Aliquam eget velit a libero suscipit tempor eget at leo. Vestibulum commodo tempor gravida. Donec dictum nunc at lorem euismod, et faucibus ante consectetur. Nullam porttitor eros in ligula posuere dignissim.",
      },
    ],
    cta: {
      label: "Read all articles on Medium",
      href: "https://medium.com/aim-labs",
    },
  },
  contact: {
    title: "We are small but we are experts",
    text:
      "If you have a website or mobile app idea in mind or you need support for your existing development team, feel free to contact us. Let's build your digital future - we aim to help you.",
    sections: [
      {
        icon: MapMarker,
        text: textToMultiline`5263 Sunset St undefined Salinas,\nWest Virginia 25420\nUnited States`,
      },
      {
        icon: MobilePhone,
        text: "(219) 555-0114",
      },
      {
        icon: PaperAirplane,
        text: "nathan.roberts@example.com",
      },
    ],
    socialLinks: [
      {
        icon: Twitter,
        href: "https://twitter.com/maichriedler",
        title: "Twitter",
      },
      {
        icon: Linkedin,
        href: "https://linkedin.com/company/maaidigital",
        title: "Linkedin",
      },
    ],
    mailer: {
      title: "Leave us a message",
      cta: "Send",
      validationSchema: Yup.object({
        name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        company: Yup.string().required("Required"),
        email: Yup.string().email("Must be an email").required("Required"),
        message: Yup.string().required("Required"),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: (values, { resetForm }) =>
        fetch("https://formspree.io/xyynbvbp", {
          method: "POST",
          redirect: "follow",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              window.alert(`Thank you for your message`);
              resetForm();
            } else {
              window.alert(`An error occured.`);
            }
          })
          .catch((e) => {
            window.alert(`An error occured.`);
          }),
      fields: [
        {
          name: "name",
          label: "Name",
          placeholder: "Your Name",
          initialValue: "",
        },
        {
          name: "company",
          label: "Company",
          placeholder: "Your company",
          initialValue: "",
        },
        {
          name: "email",
          label: "E-mail",
          placeholder: "Your email address",
          type: "email",
          initialValue: "",
        },
        {
          name: "message",
          label: "Message",
          placeholder: "Your message",
          type: "text",
          initialValue: "",
        },
      ],
    },
  },
  footer: {
    links: [
      {
        label: "Imprint",
        href: "/imprint",
      },
    ],
  },
  copyright: `© ${thisYear.getFullYear()} MAAI digital GmbH`,
};
