import "normalize.css";
import { createGlobalStyle } from "styled-components";
import SenRegularEot from "./fonts/sen-v7-latin-regular.eot";
import SenRegularWoff2 from "./fonts/sen-v7-latin-regular.woff2";
import SenRegularWoff from "./fonts/sen-v7-latin-regular.woff";
import SenRegularTtf from "./fonts/sen-v7-latin-regular.ttf";
import SenRegularSvg from "./fonts/sen-v7-latin-regular.svg";
import Sen700Eot from "./fonts/sen-v7-latin-700.eot";
import Sen700Woff2 from "./fonts/sen-v7-latin-700.woff2";
import Sen700Woff from "./fonts/sen-v7-latin-700.woff";
import Sen700Ttf from "./fonts/sen-v7-latin-700.ttf";
import Sen700Svg from "./fonts/sen-v7-latin-700.svg";

export default createGlobalStyle`

  @font-face {
    font-family: 'Sen';
    font-style: normal;
    font-weight: 400;
    src: url(${SenRegularEot}) format('eot'), /* IE */
        url(${SenRegularWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${SenRegularWoff}) format('woff'), /* Modern Browsers */
        url(${SenRegularTtf}) format('truetype'), /* Safari, Android, iOS */
        url(${SenRegularSvg}) format('svg'); /* Legacy iOS */
  }
  /* sen-700 - latin */
  @font-face {
    font-family: 'Sen';
    font-style: normal;
    font-weight: 700;
    src: url(${Sen700Eot}) format('eot'), /* IE */
        url(${Sen700Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${Sen700Woff}) format('woff'), /* Modern Browsers */
        url(${Sen700Ttf}) format('truetype'), /* Safari, Android, iOS */
        url(${Sen700Svg}) format('svg'); /* Legacy iOS */
  }
  
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    -webkit-tap-highlight-color: transparent;
    ${(props) => props.theme.body}
  }

  .sticky {
    z-index: ${(props) => props.theme.zIndex?.sticky ?? 1010};
  }

  a {
    cursor: pointer;
  }

  ${(props) => props.theme.global}
`;
